`import {currentInputsValues, setValuesForNewInputs} from 'javascripts/components/shared/custom_fields_on_form_modal'`
`import CustomSelect from 'javascripts/vendors/custom_select'`
`import perform_json_response from 'javascripts/components/helpers/perform_json_response'`
`import MultilevelSelect from 'javascripts/vendors/multilevel_select'`
`import globalSubmitOnCtrlEnterCallback from 'javascripts/components/shared/global_submit_on_ctrl_enter'`
`import update_url_parameter from 'javascripts/vendors/update_url_param'`
`import hideRepeatRecordPreviewBtn from 'javascripts/components/shared/hide_repeat_record_preview_btn'`
`import { isMassCreation } from 'javascripts/components/issues/form/helpers'`
`import RelationFieldsInterface from 'javascripts/components/issues/form/RelationFieldsInterface'`
`import Router from 'javascripts/router.js'`
`import WorkTypeFieldInterface from 'javascripts/components/issues/form/WorkTypeFieldInterface'`
`import PriorityFieldInterface from 'javascripts/components/issues/form/PriorityFieldInterface'`

IssueFormSelectBoxes = do ->

  issueForm = null
  issueRelationFields = null
  relationFields = null
  workTypeField = null
  priorityField = null

  init: (options) ->
    that = @
    issueForm = $('@new-issue-modal-dialog @new-issue-form')
    issueRelationFields = $('@new-issue-modal-dialog @issue-relation-fields')
    isRelationsPresent = issueRelationFields.length > 0
    relationFields = new RelationFieldsInterface('[data-role="issue-create-form-relation-fields"]')
    workTypeField = new WorkTypeFieldInterface()
    priorityField = new PriorityFieldInterface()

    do =>
      workTypeField.subscribeToUpdates (data) ->
        typeId = data && data.id
        if isRelationsPresent
          promise = that.send_request('type_changed')
          promise.then =>
            that.update_form_custom_parameters()
        else
          that.update_form_custom_parameters()

      priorityField.subscribeToUpdates (data) ->
        if isRelationsPresent
          that.send_request('priority_changed', null, false)

      CustomSelect.onchange $('@new-issue-form [data-field="ftselect"] select'), ->
        that.send_request('custom_parameters_changed', null, false)

      $(relationFields.fieldsBlock).on 'fields:resolve', (event) ->
        selectedValues = event.detail.selectedValues

        return unless selectedValues

        triggeredField =
          switch event.detail.triggered_field
            when 'client' then 'client_changed'
            when 'agreement_id' then 'agreement_changed'
            when 'maintenance_entity_id' then 'maintenance_entity_changed'
            when 'equipment_ids' then 'equipment_changed'
            else 'form_uploaded'

        assigneeSelect = $('@new-issue-modal-dialog #new_issue_form_assignee_id @multilevel-select')

        if assigneeSelect.length > 0
          { value, text } = selectedValues.assignee
          that.reload_assignee(assigneeSelect, { default_assignee_ids: value, default_assignee_text: text })

        that.reload_deadline(selectedValues.deadline)

        that.send_request(triggeredField)

  send_request: (triggeredAction, selectedValues = null, updateCustomParameters = true) ->
    return if $('@fields-block').hasClass('disabled')
    that = @
    issueRelationFields.attr('data-loaded', false)
    $('@fields-block').addClass('disabled')
    new Promise (resolve, reject) =>
      $.ajax
        url: '/issues/create_forms/update',
        type: 'POST',
        dataType: 'json',
        data: selectedValues || that.request_data(triggeredAction)
        success: (data, status, xhr) ->
          oldTypeId = workTypeField.selectedTypeId()
          that.request_success(data, triggeredAction)
          hideRepeatRecordPreviewBtn($('@new-issue-modal-dialog'))
          resolve()
          if updateCustomParameters
            that.actualizeCustomParameters(data, oldTypeId)

  actualizeCustomParameters: (data, oldTypeId) ->
    newWorkTypeId = data.selected_values && data.selected_values.work_type_id
    return if !newWorkTypeId && !oldTypeId || "#{newWorkTypeId}" == "#{oldTypeId}"

    @update_form_custom_parameters()

  request_data: (triggeredAction) ->
    selectedRelatedFields = relationFields.selectedValues
    selectedFormFields = {
      type_id: workTypeField.selectedTypeId(),
      priority_id: priorityField.selectedPriorityId(),
      priority_changed_manually: priorityField.changedManually(),
      triggered_action: triggeredAction,
      custom_parameters: @.collectCustomParameters(),
      repeat_from_id: issueForm.find('#issue_repeat_from_id').val(),
      mass_creation: isMassCreation(issueForm),
      parent_id: issueForm.find('#issue_parent_id').val(),
    }

    return $.extend({}, selectedRelatedFields, selectedFormFields)

  request_success: (data, triggeredAction) ->
    perform_json_response(data)
    urls = data.collection_urls
    values = data.selected_values

    if data.selected_values
      assigneeSelect = $('@new-issue-modal-dialog #new_issue_form_assignee_id @multilevel-select')
      @reload_assignee(assigneeSelect, data.selected_values) if assigneeSelect.length > 0
      @reload_deadline(data.selected_values.new_deadline)

    if values
      workTypeField.update({
        id: values.work_type_id,
        name: values.work_type_selected_text || '',
        error: values.unavailable_previous_work_type,
      })

    if values && (values.priority_id || values.unavailable_previous_priority)
      priorityField.update({
        value: values.priority_id,
        name: values.priority_selected_text || '',
        error: values.unavailable_previous_priority,
      })

    if data.url_attributes
      for selector, attributes of data.url_attributes
        for attribute, value of attributes
          $(selector).attr('href', value) if attribute == 'url'

    $('@new-agreement-modal-btn').toggleClass('hidden', $('@new-agreement-modal-btn').attr('href') == '')

    issueRelationFields.attr('data-loaded', true)
    $('@fields-block').removeClass('disabled')

  reload_deadline: (deadline) ->
    return unless deadline
    deadline.hour = "0#{deadline.hour}" if deadline.hour < 10
    deadline.minute = "0#{deadline.minute}" if deadline.minute < 10
    deadlineBlock = $('#new_issue').find('@planned-solution-time')
    dateField = deadlineBlock.find('@issue-datetime-picker')
    hourField = deadlineBlock.find('@time-picker[data-type="hour"]')
    minField = deadlineBlock.find('@time-picker[data-type="minute"]')

    if $('@new-issue-modal-dialog #issue_automatic_deadline:disabled').length > 0 && !isMassCreation(issueForm)
      dateField.val(deadline.date)
      hourField.val(deadline.hour)
      minField.val(deadline.minute)

    dateField.attr('data-holder', deadline.date)
    hourField.attr('data-holder', deadline.hour)
    minField.attr('data-holder', deadline.minute)


  reload_assignee: (widget, selected_values) ->
    return unless selected_values.default_assignee_ids && widget.attr('data-autoselect') == 'true'
    assignee_id = selected_values.default_assignee_ids[0]
    group_id = selected_values.default_assignee_ids[1]
    value = { assignee_id: assignee_id, group_id: group_id }
    MultilevelSelect.set_value(widget, value, selected_values.default_assignee_text, false)

  assignee_onchange: (idForm, event) ->
    select_tag = $("form[id='#{idForm}'] #new_issue_form_assignee_id @multilevel-select")
    MultilevelSelect.onchange(select_tag, event)

  assignee_offchange: (idForm, event) ->
    select_tag = $("form[id='#{idForm}'] #new_issue_form_assignee_id @multilevel-select")
    MultilevelSelect.offchange(select_tag, event)

  update_form_custom_parameters: ->
    that = @
    selectedValues = that.request_data()
    $('@form-issue-custom-parameters-list-container').attr('data-loaded', false)
    items = $('@form-issue-custom-parameters-list-container .form-issue-custom-parameters-item')
    custom_parameters_inputs_values = currentInputsValues(items, 'issue')
    url = Router.issuesCreateFormsParametersIndexPath();
    url = update_url_parameter(url, 'issue_type_id', workTypeField.selectedTypeId())
    url = update_url_parameter(url, 'company_id', selectedValues.company_id)
    $.ajax({ url: url, datatype: "json" }).done (data) ->
      perform_json_response(data)
      container = $('@form-issue-custom-parameters-list-container .form-issue-custom-parameters-item')
      setValuesForNewInputs(custom_parameters_inputs_values, container, 'issue')
      $('@form-issue-custom-parameters-list-container').attr('data-loaded', true)
      $('@new-issue-parameters-list-parts-form .form-issue-custom-parameters-list').unwrap()

      CustomSelect.onchange $('@new-issue-form [data-field="ftselect"] select'), ->
        that.send_request('custom_parameters_changed', null, false)

      $('@new-issue-form').find('textarea, input[type="text"]').off('keydown').on 'keydown', (e) ->
        globalSubmitOnCtrlEnterCallback(e, $('@new-issue-form'))

      $('@new-issue-form').validate()
      that.send_request('custom_parameters_changed', null, false)

  collectCustomParameters: ->
    issueForm.find('[data-field="ftselect"] select').toArray()
      .reduce(((acc, element) ->
         acc[$(element).data('custom-select-id')] = $(element).val()
         acc),
         {})

`export default IssueFormSelectBoxes`
